@font-face {
  font-family: "Graphik";
  src:
    url("../public/assets/font/Graphik-Regular.woff") format("woff"),
    url("../public/assets/font/Graphik-Bold.woff") format("woff"),
    url("../public/assets/font/Graphik-Light.woff") format("woff");
}
body {
  margin: 0;
  font-family: 'Graphik', sans-serif;

}

code {
  font-family: 'Graphik', sans-serif;
}
