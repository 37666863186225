$primary-color: #f1f8fc;
$secondary-color: #0074c7;

header {
  nav {
    background-color: $primary-color;
    box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.3);

    .nav-link {
      color: $secondary-color;
    }
    .nav-link.active {
      color: #384050;
    }
    .navbar-toggler {
      color: $secondary-color;
    }
    font: {
      family: "Graphik", sans-serif;
      size: 1rem;
    }
    .artisan-results {
      border-radius: 15px;
      background-color: $primary-color;
      border: 0.3px solid #ccc;
      z-index: 1000;
      width: 400px;
      max-height: 400px;
      overflow-y: auto;
      padding: 10px;
      a {
        text-decoration: none;
        color: $secondary-color;
        .card-rubrique {
          color: #00497c;
          font: {
            weight: bold;
          }
        }
      }
      p {
        line-height: 1;
      }
      a:hover {
        color: #384050;
        .card-rubrique {
          color: #384050;
        }
      }
    }
    .artisan-card {
      margin: 3px 0 3px 0;
      border-bottom: 1px solid $secondary-color;
    }
  }
}

@media (min-width: 993px) {
  .logo-tta {
    width: 180px;
    height: auto;
  }
  .artisan-results {
    position: absolute;
    top: 60px;
    right: 20px;
    width: calc(50% - 40px);
  }
}

@media (max-width: 992px) {
  .logo-tta {
    width: 140px;
    height: auto;
  }
  .artisan-results {
    position: static;
    width: 100%;
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .logo-tta {
    width: 120px;
    height: auto;
  }
  .artisan-results {
    position: static;
    width: 100%;
    max-height: 200px;
  }
}
