@charset "UTF-8";
footer {
  background-color: #0074c7;
  color: #f1f8fc;
  font-family: "Graphik", sans-serif;
  font-size: 0.8rem;
  text-align: center;
}
footer .footer-link {
  color: #f1f8fc;
  text-decoration: none;
}
footer .footer-link:hover {
  color: #384050;
}
footer hr {
  border: 1px solid #2ea8ff; /* Ligne de séparation plus claire */
}

