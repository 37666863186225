header nav {
  background-color: #f1f8fc;
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.3);
  font-family: "Graphik", sans-serif;
  font-size: 1rem;
}
header nav .nav-link {
  color: #0074c7;
}
header nav .nav-link.active {
  color: #384050;
}
header nav .navbar-toggler {
  color: #0074c7;
}
header nav .artisan-results {
  border-radius: 15px;
  background-color: #f1f8fc;
  border: 0.3px solid #ccc;
  z-index: 1000;
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}
header nav .artisan-results a {
  text-decoration: none;
  color: #0074c7;
}
header nav .artisan-results a .card-rubrique {
  color: #00497c;
  font-weight: bold;
}
header nav .artisan-results p {
  line-height: 1;
}
header nav .artisan-results a:hover {
  color: #384050;
}
header nav .artisan-results a:hover .card-rubrique {
  color: #384050;
}
header nav .artisan-card {
  margin: 3px 0 3px 0;
  border-bottom: 1px solid #0074c7;
}

@media (min-width: 993px) {
  .logo-tta {
    width: 180px;
    height: auto;
  }
  .artisan-results {
    position: absolute;
    top: 60px;
    right: 20px;
    width: calc(50% - 40px);
  }
}
@media (max-width: 992px) {
  .logo-tta {
    width: 140px;
    height: auto;
  }
  .artisan-results {
    position: static;
    width: 100%;
    max-height: 300px;
  }
}
@media (max-width: 768px) {
  .logo-tta {
    width: 120px;
    height: auto;
  }
  .artisan-results {
    position: static;
    width: 100%;
    max-height: 200px;
  }
}

